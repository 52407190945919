import { useState, useEffect, createRef } from 'react';
import {
  Grid,
  Button,
  Typography,
  Backdrop,
  CircularProgress
} from "@mui/material";
import Shell from 'components/shell';
import { SubmitButton } from 'components/buttons';
import { formatPhoneNumber, formatDate } from 'utilities';
import classes from './index.module.css';
import copy from 'constants/copy';
import states from 'constants/states';
import { isFormValid, Input, getFormValues } from 'components/controls';
import { isOver18, nameValidator } from 'utilities';
import { useParams, useNavigate } from 'react-router-dom';
import { updateRegistration, updateDependentInvite, updateDependentEnrolment } from '../../data';

const subjects = ['employee', 'provider', 'dependent'];

const Register = () => {
  let [subject] = useState(useParams().subject);
  const [registrationValues, updateRegistrationValues] = useState({});
  const [errors, updateErrors] = useState([]);
  const [dependentEmails, updateDependentEmails] = useState([]);
  const [submitable, updateSubmitable] = useState(false);
  const [loading, updateLoading] = useState(false);
  const formRef = createRef();
  let { sponsor, code } = useParams();
  const navigate = useNavigate();

  // if(!subjects.includes(subject)) subject = subjects[0];

  const messages = {
    title: copy(`enrollment.title.${subject}`, ['BOLD Capital Partners']),
    subtitle: copy(`enrollment.subtitle.${subject}`),
    formTitle: copy(`enrollment.form.title.${subject}`),
    formSubtitle: copy(`enrollment.form.subtitle.${subject}`, null, ''),
    allFieldsRequired: copy(`enrollment.form.allFieldsRequired.${subject}`),
    firstNameLabel: copy(`enrollment.form.firstName.${subject}`),
    lastNameLabel: copy(`enrollment.form.lastName.${subject}`),
    dateOfBirthLabel: copy(`enrollment.form.dateOfBirth.${subject}`),
    emailLabel: copy(`enrollment.form.email.${subject}`),
    dependentEmailLabel: copy(`enrollment.form.dependentEmail.${subject}`, '1'),
    phoneLabel: copy(`enrollment.form.phone.${subject}`),
    stateLabel: copy(`enrollment.form.state.${subject}`),
    consent: copy(`enrollment.form.consent.${subject}`),
    marketing: copy(`enrollment.form.marketing.${subject}`),
    dependentButton: copy(`enrollment.dependentButton.${subject}`),
    submitButton: copy(`enrollment.form.submitButton.${subject}`),
    enrollButton: copy(`enrollment.form.enrollButton.${subject}`),
    eligibilityTitle: copy(`enrollment.eligibility.title.${subject}`),
    diseaseTitle: copy(`enrollment.eligibility.diseaseTitle.${subject}`),
    diseaseDescription: copy(`enrollment.eligibility.diseaseDescription.${subject}`),
    failureTitle: copy(`enrollment.eligibility.failureTitle.${subject}`),
    failureDescription: copy(`enrollment.eligibility.failureDescription.${subject}`),
    surgeryTitle: copy(`enrollment.eligibility.surgeryTitle.${subject}`),
    surgeryDescription: copy(`enrollment.eligibility.surgeryDescription.${subject}`),
    restrictionTitle: copy(`enrollment.eligibility.restrictionTitle.${subject}`),
    restrictionDescription: copy(`enrollment.eligibility.restrictionDescription.${subject}`),
    dialysisTitle: copy(`enrollment.eligibility.dialysisTitle.${subject}`),
    dialysisDescription: copy(`enrollment.eligibility.dialysisDescription.${subject}`),
  }

  const handleRegistrationChange = e => {
    const target = e.target;
    let newValue = target.value;
    const name = target.name;

    if(name === 'phoneNumber') newValue = formatPhoneNumber(newValue);
    else if (name === 'dateOfBirth') newValue = formatDate(newValue);
    else if (name === 'firstName') newValue = nameValidator(newValue);
    else if (name === 'lastName') newValue = nameValidator(newValue);
    
    if (target.type === "checkbox") newValue = target.checked;

    updateSubmitable(isFormValid(formRef.current));
    updateRegistrationValues({ ...registrationValues, [name]: newValue });
    updateErrors([]);
  };

  const fieldConfigs = {
    firstName: { type: 'text', label:messages.firstNameLabel, value:registrationValues.firstName, name: 'firstName', onChange:handleRegistrationChange, validation: ['string'] },
    lastName: { type: 'text', label:messages.lastNameLabel, value:registrationValues.lastName, name: 'lastName', onChange:handleRegistrationChange, validation: ['string'] },
    dateOfBirth: {  type: 'text', label:messages.dateOfBirthLabel, value:registrationValues.dateOfBirth, name: 'dateOfBirth', onChange:handleRegistrationChange, placeholder: 'mm/dd/yyyy',
                    validation: ['date', { validation: isOver18, message: 'You must be over 18.' }] },
    email: { type: 'email', label:messages.emailLabel, value:registrationValues.email, name: 'email', onChange:handleRegistrationChange, validation: ['email'] },
    phone: { type: 'text', label:messages.phoneLabel, value:registrationValues.phoneNumber, name: 'phoneNumber', onChange:handleRegistrationChange, validation: ['phone'] },
    state: { type: 'select', label:messages.stateLabel, value:registrationValues.state, name: 'state', onChange:handleRegistrationChange, validation: ['selected'] },
    consent: { type:'checkbox', label:messages.consent, value:registrationValues.consent, name: 'consentTosPp', onChange:handleRegistrationChange, validation: ['checked'] },
    marketing: { type:'checkbox', label:messages.marketing, value:registrationValues.marketing, name: 'consentMarketing', onChange:handleRegistrationChange }
  };
  
  const [canAddMoreDependent, setCanAddMoreDependent] = useState(true)
  const addDependentEmail = () => {
    const emails = dependentEmails.slice();
    const index = dependentEmails.length + 1;
    const emailConfig =  {
      label: copy(`enrollment.form.dependentEmail.${subject}`, [index]),
      name: `dependentEmail${index}`,
      type: 'email',
      index,
      validation: ['email']
    };
    emails.push(emailConfig);
    console.log(emails);
    if(emails.length > 2){
      setCanAddMoreDependent(false)
    }
    updateDependentEmails(emails);
    updateSubmitable(isFormValid(formRef.current));
  }

  const removeDependentEmail = function(i) {
    const emails = dependentEmails.slice()
    // emails.splice(i, 1);
    emails.pop()
    if(emails.length < 3){
      setCanAddMoreDependent(true)
    }
    updateDependentEmails(emails);
  }

  const handleSubmit = async e => {
    e.preventDefault();
    if(isFormValid(e.target)) {
      const formValues = getFormValues(e.target);
      let resp;
      updateLoading(true);
      if(subject === 'provider'){
        resp = await updateDependentInvite(formValues);
      }else if(subject === 'employee'){
        resp = await updateRegistration(formValues);
      }else{
        resp = await updateDependentEnrolment(formValues)
      }
      if (resp.errors) updateErrors(resp.errors);
      else navigate(`/confirmation/${sponsor}/${subject}`);
      updateLoading(false);
    }
  }
  useEffect(() => {
    window.scrollTo(0,0)
    subject === 'provider' && addDependentEmail()
  }, []);
  useEffect(() => updateSubmitable(isFormValid(formRef.current)), [dependentEmails]);

  return (
    <Shell>
      <Grid container>
        <Grid item xs={2}></Grid>
        <Grid item xs={8} className={classes.titles}>
          <Typography variant="h2">{messages.title}</Typography>
          <p className={classes['subheadline']}>{messages.subtitle}</p>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item md={6} xs={12} order={{ xs: 2, md: 1 }} className={classes['backdrop-container']}>
          <Backdrop open={loading} className={classes.backdrop}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <form onSubmit={handleSubmit} className={classes['registration-form']} ref={formRef}>
            <input type="hidden" name="sponsorSymbol" value={sponsor} data-valid="true" />
            {subject === 'dependent' && <input type="hidden" name="id" value={code} data-valid="true" />}
            <Typography variant="h3" className={classes['registration-headline']}>
              {messages.formTitle} <span className={classes['all-fields-required']}>{messages.allFieldsRequired}</span>
            </Typography>
            <div className={`${classes['registration-error-display']} ${errors.length ? classes['registration-display-errors'] : ''}`}>
              {errors.map((error, arr, i) => (
                <Typography variant="h4" key={error.id}>
                  {error.title === 'Error Dependent Does Not Exist'
                    ? <>Could not find your email in the system, please inform employee to invite you</>
                    : <>Invalid user information. Please verify that you’ve entered the correct information.</>
                  }
              </Typography>
              ))}
            </div>
            <Typography variant="p" className={classes['registration-subtitle']}>
              {messages.formSubtitle}
            </Typography>
            { (subject === 'employee' || subject === 'dependent') &&
              <Grid container spacing={1} className={classes['form-fields']}>
                <Grid item xs={6} className={classes['form-row']}><Input {...fieldConfigs.firstName} /></Grid>
                <Grid item xs={6} className={classes['form-row']}><Input {...fieldConfigs.lastName} /></Grid>
                <Grid item xs={12} className={classes['form-row']}><Input {...fieldConfigs.dateOfBirth} /></Grid>
                <Grid item xs={12} className={classes['form-row']}><Input {...fieldConfigs.email} /></Grid>
                {/* <Grid item xs={12} className={classes['form-row']}><Input {...fieldConfigs.phone} /></Grid> */}
                <Grid item xs={12} className={classes['form-row']}><Input {...fieldConfigs.state} options={states} /></Grid>
                <Grid item xs={12} className={classes['form-consent']}><Input {...fieldConfigs.consent} /></Grid>
                <Grid item xs={12} className={classes['form-consent']}><Input {...fieldConfigs.marketing} /></Grid>
              </Grid>
            }
            { subject === 'provider' && <>
              <Grid container spacing={1} className={classes['form-fields']}>
                <Grid item xs={6} className={classes['form-row']}><Input {...fieldConfigs.firstName} /></Grid>
                <Grid item xs={6} className={classes['form-row']}><Input {...fieldConfigs.lastName} /></Grid>
                <Grid item xs={12} className={classes['form-row']}><Input {...fieldConfigs.email} /></Grid>
                  {
                    dependentEmails.map(email => {
                      const config = { 
                        ...email,
                        value: registrationValues[`dependentEmail${email.index}`] || '',
                        onChange: handleRegistrationChange,
                        remove: email.index > 1 ? removeDependentEmail.bind(null, email.index - 1) : null
                      };
                      return <Grid item xs={12} className={classes['form-row']} key={email.index}><Input {...config} /></Grid>
                    }) 
                  }
              </Grid>
              <Grid item xs={12} className={classes['add-dependent-container']}>
                <Button disabled={!canAddMoreDependent} variant="outlined" className={classes['invite-outline-button']} fullWidth={true} onClick={addDependentEmail}>{messages.dependentButton}</Button>
              </Grid>              
            </>
            }
            <Grid item xs={12} className={classes['submit-button-container']}>
              <SubmitButton text={subject === 'provider' ? messages.submitButton : messages.enrollButton} fullWidth={true} disabled={!submitable || loading} />
            </Grid>
          </form>
        </Grid>
        <Grid item md={6} xs={12}order={{ xs: 1, md: 2 }}>
          <div className={classes.eligibility}>
            <Typography variant="p" className={classes['eligibility-intro']}>{messages.eligibilityTitle}:</Typography>
            <Typography variant="h4" className={classes['eligibility-headline']}>{messages.diseaseTitle}</Typography>
            <Typography variant="p" className={classes['eligibility-label']}>{messages.diseaseDescription}</Typography>
            <Typography variant="h4" className={classes['eligibility-headline']}>{messages.failureTitle}</Typography>
            <Typography variant="p" className={classes['eligibility-label']}>{messages.failureDescription}</Typography>
            <Typography variant="h4" className={classes['eligibility-headline']}>{messages.surgeryTitle}</Typography>
            <Typography variant="p" className={classes['eligibility-label']}>{messages.surgeryDescription}</Typography>
            <Typography variant="h4" className={classes['eligibility-headline']}>{messages.restrictionTitle}</Typography>
            <Typography variant="p" className={classes['eligibility-label']}>{messages.restrictionDescription}</Typography>
            <Typography variant="h4" className={classes['eligibility-headline']}>{messages.dialysisTitle}</Typography>
            <Typography variant="p" className={classes['eligibility-label']}>{messages.dialysisDescription}</Typography>
          </div>
        </Grid>
      </Grid>
      
    </Shell>
  )
}

export default Register;