import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import styles from './index.module.css';

const getButton = (variant, fullWidth, text) => {
  return <Button variant={variant} color="secondary" fullWidth={fullWidth}>{text}</Button>
}

const OutlinedButton = props => {
  return <Link to={props.link} className={styles['enroll-button']}>{getButton('outlined', props.fullWidth, props.text)}</Link>
}

const SolidButton = props => {
  return <Link to={props.link} className={styles['enroll-button']}>{getButton('contained', props.fullWidth, props.text)}</Link>
}

const SubmitButton = props => {
  return <Button variant="contained" color="secondary" onSubmit={props.action} fullWidth={props.fullWidth} disabled={props.disabled} type="submit" className={styles['enroll']}>{props.text}</Button>
}

export { OutlinedButton, SolidButton, SubmitButton };