import { app as appInitialState } from '../model/initialState';
export const BASE = 'i/v1/kcc/';
export const ENDPOINT = `${appInitialState.ENDPOINT}`;
export const GET_CONFIG = {
  method: 'GET',
  mode: 'cors',
  headers: {Accept: 'application/json'}
};
export const PUT_CONFIG = {
  method: 'PUT',
  mode: 'cors',
  headers: { 'Content-Type': 'application/json' }
}
export const POST_CONFIG = {
  method: 'POST',
  mode: 'cors',
  headers: { 'Content-Type': 'application/json' }
}

export const PATHS = {
  registration: `${BASE}users/enrollments`,
  enrolDependent: `${BASE}dependents/enroll`,
  inviteDependent: `${BASE}dependents/add`
}