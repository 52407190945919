import { DateTime } from 'luxon';
const dateFormat = 'mm/dd/yyyy';
const phonePattern = /^\d{3}-\d{3}-\d{4}$/

const formatPhoneNumber = phone => {
  let numbers = phone.replace(/\D/g, '');
  const areaCode = numbers.slice(0,3);
  const prefix = numbers.slice(3,6);
  const lastFour = numbers.slice(6,10);
  const firstDash = prefix ? '-' : '';
  const secondDash = lastFour ? '-' : '';
  return`${areaCode}${firstDash}${prefix}${secondDash}${lastFour}`;
}

const formatDate = unformatted => {
  let numbers = unformatted.replace(/\D/g, '');
  const month = numbers.slice(0,2);
  const date = numbers.slice(2,4);
  const year = numbers.slice(4,8);
  const dateSlash = date ? '/' : '';
  const yearSlash = year ? '/' : '';
  return `${month}${dateSlash}${date}${yearSlash}${year}`;
}

const isDateValid = unvalidated => {
  return DateTime.fromFormat(formatDate(unvalidated), dateFormat).isValid;
}

const isPhoneValid = unvalidated => {
  return phonePattern.test(formatPhoneNumber(unvalidated));
}

/* eslint-disable-next-line no-useless-escape */
const isEmailValid = email => (/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i).test(email);

const isStringValid = str => Boolean(str.replace(/ /g, '').length);

const isValueSelected = value => isStringValid(value);

const isChecked = value => value;

const isOver18 = date => {
  const eighteenYears = 60 * 1000 * 60 * 24 * 365 * 18;
  const start = +new Date(`${formatDate(date)} 00:00`);
  const today = +new Date();
  const diff = today - start;
  return diff >= eighteenYears;
}

//========= name validation --> name input field should not enable user to type numeric values ======//
const nameValidator = (value) => {
  let name = value.replace(/[^A-Z,a-z]/i , '')
  return name
}

export { formatPhoneNumber, formatDate, isDateValid, isPhoneValid, isEmailValid, isStringValid, isValueSelected, isChecked, isOver18, nameValidator };