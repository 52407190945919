import React from 'react';
import { ReactComponent as AliveCorLogo } from 'assets/img/alivecor-logo.svg';
import styles from './index.module.css';

const Logo = props => {
  
  return (
    <div className={styles['logo-container']}>
      <AliveCorLogo className={styles['logo']} alt="" />
    </div>
  );
}

export default Logo;