import { useParams } from "react-router-dom";
import { Grid, Typography, Box } from '@mui/material';
import Shell from '../shell';
import { OutlinedButton } from '../buttons';
import { ReactComponent as Success } from 'assets/img/success.svg';
import classes from './index.module.css';

const Enrollment = () => {
  const params = useParams();
  return (
    <Shell>
      <Grid container className={classes.container}>
        <Grid item sm={12} md={6} lg={6} xl={6}>
          <Success />
          <Typography variant="h1" className={classes.textFont}>Who do you want to enroll today?</Typography>
          <Box className={classes.button_alignment}> 
            <OutlinedButton sponsor={params.sponsor} text="enroll myself" link="employee" />
            <OutlinedButton sponsor={params.sponsor} text="add a dependent" link="provider" />
          </Box>
        </Grid>
      </Grid>
    </Shell>
  )
}

export default Enrollment;