import { useEffect } from 'react';
import Shell from 'components/shell';
import { Grid, Typography, Box } from '@mui/material';
import { ReactComponent as Success } from 'assets/img/success.svg';
import { OutlinedButton } from 'components/buttons';
import { useParams } from 'react-router-dom';
import classes from './index.module.css';

const Confirmation = () => {
  const { sponsor, code, subject } = useParams();
  const addDependentLink = `/enroll/${sponsor}/provider`;
  const enrollMyselfLink = `/enroll/${sponsor}/employee`;

  useEffect(() => window.scrollTo(0,0))
  return (
    <Shell>
      { ((subject === 'employee' || subject ==='dependent') && 
        <Grid container className={classes.confirmation}>
          <Grid item md={6} className={classes['completion-message']}>
            <Success />
            <Typography variant="h1" className={classes.enrollment_header}>Enrollment Complete</Typography>
            <Typography variant="p" className={classes['success-message']}>
              Please check your email for a confirmation email from kcsupport@alivecor.com.
              If you have questions or concerns please feel free to reach out to our customer care team.
            </Typography>
            <Box className={classes['invite-dependent-button-section']}>
            { subject === 'employee' &&
              <>
                <Typography variant="h4" className={classes['sub-headline']}>Have dependents you want to enroll in KardiaComplete?</Typography>
                <div className={classes['add-dependent-container']}>
                  <OutlinedButton text="invite a dependent to enroll" link={addDependentLink} />
                </div>
              </>
            }
            </Box>
          </Grid>
          <Grid item md={6} className={classes['code-and-steps']}>
            <Box>
              <Typography variant="h3" className={classes['next-steps']}>Next steps</Typography>
              <ul className={classes['steps-list']}>
                <li>
                  <Typography variant="h3" className={classes.step1}>Receive an email from kcsupport@alivecor.com</Typography>
                </li>
                <li>
                  <Typography variant="h3" className={classes.step1}>Download the <span style={{color: '#067F6F'}}>KardiaComplete</span> app by clicking the app store link in your email</Typography>
                </li>
                <li>
                  <Typography variant="h3" className={classes.step1}>Create your account by entering your 12-digit code from the email </Typography>
                </li>
              </ul>
            </Box>
            
          </Grid>
        </Grid>
        ) || ( subject === 'provider' &&
        <Grid container className={classes.confirmation}>
          <Grid item md={6} className={classes['completion-message']}>
            <Success />
            <Typography variant="h1" className={classes.enrollment_header}>Dependents Added</Typography>
            <Typography variant="p" className={classes['success-message']} >
              An email from kcsupport@alivecor.com has been sent to your dependent(s) with a link to where they can enroll in KardiaComplete.
            </Typography>
            <div className={classes['add-dependent-container']}>
              <OutlinedButton link={enrollMyselfLink} text="enroll myself" />
              <OutlinedButton link={addDependentLink} text="invite another dependent" />
              {/* <div className={classes['another-dependent-container']}>
              </div> */}
            </div>
          </Grid>
        </Grid>
        )
      }
    </Shell>
  );
}

export default Confirmation;
