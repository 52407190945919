import { Link } from 'react-router-dom';
import { Container, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Logo from '../logo';
import { Twitter, Instagram, LinkedIn, Facebook } from '@mui/icons-material';
import colors from '../../assets/css/colors.module.css';
import globalStyles from '../../assets/css/index.module.css';

const containerUseStyles = makeStyles(theme => ({
  expand: {
    minHeight: '100vh',
  },
  container: {
    flexDirection: 'column',
    flexWrap: 'nowrap',
    alignItems: 'stretch'
  },
  topAlign: {
    alignContent: 'start'
  },
  logo: {
    flexShrink: 0,
    height: '96px',
    minHeight: '96px'
  },
  footerLogo: {
    paddingTop: '12px',
    [theme.breakpoints.down('lg')]: {
      paddingTop: 0
    }
  },
  children: {
    flexGrow: 1,
    flexShrink: 0,
    backgroundColor: '#F2F5F3'
  },
  childrenContainer: {
    padding: '50px 10px'
  },
  careTitle: {
    marginTop: '40px',
    [theme.breakpoints.down('lg')]: {
      display: 'block',
      textAlign: 'center'
    }
  },
  'support-link': {
    [theme.breakpoints.down('lg')]: {
      display: 'block',
      marginRight: 0,
      textAlign: 'center'
    }
  },
  footer: {
    flexShrink: 0,
    backgroundColor: 'white',
    borderTop: `4px solid ${colors.brand}`
  },
  'social-web-container': {
    position: 'relative'
  },
  'social-web': {
    marginTop: '30px',
    [theme.breakpoints.down('lg')]: {
      marginTop: 0
    }
  },
  domain: {
    fontWeight: 600,
    fontSize: '18px',
    textDecoration: 'none',
  },
  domainContainer: {
    textAlign: 'right',
    lineHeight: '43px',
    [theme.breakpoints.down('lg')]: {
      textAlign: 'center'
    }
  },
  icons: {
    color: colors.brand,
    textAlign: 'right',
    letterSpacing: '5px',
    [theme.breakpoints.down('lg')]: {
      textAlign: 'center'
    }
  },
  icon: {
    fontSize: '20px',
    marginLeft: '21px'
  },
  legalLinks: {
    textAlign: 'center'
  },
  legalLink: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '13px',
    lineHeight: '21px',
    display: 'inline-block',
    '& a': {
      color: 'black'
    },
    marginLeft: '35px',
    '&:first-child': {
      marginLeft: 0
    }
  },
  copyright: {
    fontSize: '12px',
    color: colors.smoke,
    textAlign: 'center'
  }
}));

const Shell = props => {
  const containerStyles = containerUseStyles();
  const supportLinkClasses = `${containerStyles['support-link']} ${globalStyles['support-link']}`;
  return (
    <Grid container className={`${containerStyles.expand} ${containerStyles.container}`}>
      <Grid item lg={12} className={containerStyles.logo}>
        <Container maxWidth="lg">
          <Logo />
        </Container>
      </Grid>
      <Grid item lg={12} className={containerStyles.children}>
        <Container maxWidth="lg" classes={{root:containerStyles.childrenContainer}}>{props.children}</Container>
      </Grid>
      <Grid item xs={12} lg={12} className={containerStyles.footer}>
        <Container maxWidth="lg">
          <Grid container spacing={{xs: 0, lg: 8}}>
            <Grid item xs={12} lg={4}>
              <Typography variant="h4" className={containerStyles.careTitle}>Customer Care</Typography>
              <a href="tel:+18553388800" className={`${supportLinkClasses} ${globalStyles['phone']}`}>+1 (855) 578-1085</a>
              <a href="mailto:kcsupport@alivecor.com" className={supportLinkClasses}>kcsupport@alivecor.com</a>
              {/* <a href="http://kardia.com/kardiacomplete/tos" target="_blank" rel="noopener noreferrer" className={supportLinkClasses}>kcsupport@alivecor.com</a> */}
            </Grid>
            <Grid item xs={12} lg={4}>
              <div className={containerStyles.footerLogo}>
                <Logo />
              </div>
            </Grid>
            <Grid item xs={12} lg={4} className={containerStyles['social-web-container']}>
              <Grid container className={containerStyles['social-web']}>
                <Grid item xs={12} className={containerStyles.domainContainer}>
                  <a href="https://www.kardia.com" className={containerStyles.domain}>kardia.com</a>
                </Grid>
                <Grid item className={containerStyles.icons} xs={12}>
                  <Twitter className={containerStyles.icon} />
                  <LinkedIn className={containerStyles.icon} />
                  <Instagram className={containerStyles.icon} />
                  <Facebook className={containerStyles.icon} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <div className={containerStyles.legalLinks}>
                <Typography variant="h4" className={containerStyles.legalLink}><a href="https://www.kardia.com/telehealth-informed-consent" target="_blank" rel="noopener noreferrer">Telehealth Informed Consent</a></Typography>
                <Typography variant="h4" className={containerStyles.legalLink}><a href="http://www.kardia.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a></Typography>
                <Typography variant="h4" className={containerStyles.legalLink}><a href="http://kardia.com/kardiacomplete/tos" target="_blank" rel="noopener noreferrer">Terms & Conditions</a></Typography>
              </div>
              <p className={containerStyles.copyright}>
                ©2021 AliveCor, Inc. All Rights Reserved. US Patent Nos 8,301,232, 8,509,882 and Patents Pending.
                AliveCor and Kardia are trademarksof AliveCor, Inc. in the United States and other countries.
              </p>
            </Grid>
          </Grid>

        </Container>
      </Grid>
    </Grid>
  )
}

export default Shell;